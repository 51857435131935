import classes from "./Home.module.css";

const Home = () => {
  return (
    <div className={classes.home} id="home">
      <h1 className={classes.homespan}>
        Canine Obedience and Behavioral Specialists
      </h1>
    </div>
  );
};

export default Home;
