import React from "react";
import classes from "./Form.module.css";

const Step2 = (props) => {
  const { data, onCancel } = props;
  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <h3 className={classes.title}>{data.packageName}</h3>
      </header>
      <div className={classes.container}>
        <h3>Average pricing of {data.packageName} level training:</h3>
        <div className={classes.description}>{data.avgPrice}</div>
      </div>
      <div className={classes.buttonContainer}>
        <strong>
          Schedule a<span className={classes.highlighted}>FREE</span>
          30 minute consultation and canine evaluation
        </strong>
        <br />
        <br />
        <a href="mailto:training@pinpointcanine.com">
          <button>Contact us!</button>
        </a>
        <button onClick={onCancel}>Close</button>
      </div>
    </div>
  );
};
export default Step2;
