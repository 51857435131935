import classes from "./Services.module.css";
const Services = () => {
  return (
    <div className={classes.services} id="services">
      <h2>Our Services</h2>
      <div className={classes.servicesItems}>
        <div className={classes.servicesItem}>
          <h4>One on One Training</h4>
          <p>
            With Pinpoint, you'll receive a training experience curated
            especially for your pup!
          </p>
        </div>
        <div className={classes.servicesItem}>
          <h4>Positive Only Environment</h4>
          <p>
            Our trainers are committed to using only positive, research-backed
            training methods, without needing to resort to painful tools like
            shock or prong collars
          </p>
        </div>
        <div className={classes.servicesItem}>
          <h4>Specialized Certifications Prep</h4>
          <p>
            Looking to have your dog prepare for an AKC Canine Good Citizen test
            or perform for another evaluation? Look no further!
          </p>
        </div>
        <div className={classes.servicesItem}>
          <h4>Online Coaching</h4>
          <p>
            Can't make it to the lesson? Our trainers can help you solve
            behavioral problems with a simple video-conference meeting -
            teaching YOU how to be your dog's trainer!
          </p>
        </div>
        <div className={classes.servicesItem}>
          <h4>Service Dog Services</h4>
          <p>
            Looking to prepare your dog to become a service dog? Or understand
            the process? Ask us! Qualified service dogs can help you perform
            much needed tasks!
          </p>
        </div>
        <div className={classes.servicesItem}>
          <h4>Dog Walking, Sitting, and Grooming!</h4>
          <p>
            Need us to check on your dog while you're at work or traveling?
            Can't quite get the hang of nail clipping, teeth brushing, or
            bathtime? Contact us for a no-fuss scheduling process!
          </p>
        </div>
      </div>
      <a href="mailto:training@pinpointcanine.com">
        <button>Get in Touch</button>
      </a>
    </div>
  );
};
export default Services;
