import React, { useState } from "react";
import PackageData from "./form/PackageData";
import FormWizard from "./form/FormWizard";
import Card from "../shared/Card";
import Modal from "../shared/Modal";
import classes from "./Packages.module.css";

const Packages = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const openLilyModal = () => {
    setShowConfirmModal(true);
    setModalContent(
      <FormWizard
        onCancel={closeModalHandler}
        packageName={PackageData[0].packageName}
        description={PackageData[0].description}
        skills={PackageData[0].skills}
        avgPrice={PackageData[0].avgPrice}
      />
    );
  };
  const openLincolnModal = () => {
    setShowConfirmModal(true);
    setModalContent(
      <FormWizard
        onCancel={closeModalHandler}
        packageName={PackageData[1].packageName}
        description={PackageData[1].description}
        skills={PackageData[1].skills}
        avgPrice={PackageData[1].avgPrice}
      />
    );
  };
  const openWinstonModal = () => {
    setShowConfirmModal(true);
    setModalContent(
      <FormWizard
        onCancel={closeModalHandler}
        packageName={PackageData[2].packageName}
        description={PackageData[2].description}
        skills={PackageData[2].skills}
        avgPrice={PackageData[2].avgPrice}
      />
    );
  };

  const closeModalHandler = () => {
    setShowConfirmModal(false);
  };

  return (
    <React.Fragment>
      <Modal
        show={showConfirmModal}
        onCancel={closeModalHandler}
        content={modalContent}
      />
      <div className={classes.packages} id="packages">
        <h2>Our Packages</h2>
        <div className={classes.packagesItems}>
          <br />
          <Card
            title="The Lily: Foundations"
            onClick={openLilyModal}
            img="Lily"
          >
            <ul>
              <li>Forms basic manners</li>
              <li>Good for puppies</li>
              <li>Good for older dogs new to training</li>
              <li>Good for first-time dog owners</li>
            </ul>
          </Card>
          <Card
            title="The Lincoln: Intermediate"
            onClick={openLincolnModal}
            img="Lincoln"
          >
            <ul>
              <li>
                Builds upon earlier foundational skills in the Lily package
              </li>
              <li>Targets specific new skillsets</li>
              <li>Owner-requested specialized skills </li>
            </ul>
          </Card>
          <Card
            title="The Winston: Behavioral"
            onClick={openWinstonModal}
            img="Winston"
          >
            <ul>
              <li>Focuses on adjusting problematic behaviors</li>
              <li>stops aggression</li>
              <li>stops destructive actions</li>
              <li>stops fearful traits</li>
            </ul>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Packages;
