import React from "react";
import classes from "./Form.module.css";
const Step3 = (props) => {
  const { data, handleChange, next, back } = props;
  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <h3>About You</h3>
      </header>
      <form className={classes.form}>
        <div className={classes.row}>
          <div className={classes.inputLabel}>
            <label htmlFor="clientname">Your Name:</label>
          </div>
          <div className={classes.inputGroup}>
            <input
              type="text"
              name="clientname"
              placeholder="Your name"
              value={data.clientname}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>
            <label htmlFor="email">Your Email:</label>
          </div>
          <div className={classes.inputGroup}>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={data.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>
            <label htmlFor="phone">Your Phone:</label>
          </div>
          <div className={classes.inputGroup}>
            <input
              type="tel"
              name="phone"
              placeholder="Your Phone"
              value={data.phone}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.inputLabel}>
            <label htmlFor="contactpreference">
              How would you prefer we contact you?
            </label>
          </div>
          <div className={classes.inputGroup}>
            <input
              type="text"
              name="contactpreference"
              placeholder="By Phone/By Email"
              value={data.contactpreference}
              onChange={handleChange}
            />
          </div>
          <div className={classes.container}></div>
        </div>
        <div className={classes.buttonContainer}>
          <strong>We will NEVER share your information!</strong>
          <br />
          <button onClick={back}>Back</button>
          <button onClick={next}>Next</button>
        </div>
      </form>
    </div>
  );
};
export default Step3;
