import React, { useState } from "react";
import classes from "./Form.module.css";
const Submit = (props) => {
  const { data, back } = props;

  const [status, setStatus] = useState("unsent");
  const sending = "Sending...";
  const submitted = "Submitted!";
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(sending);
    // const { name, email, message } = e.target.elements;
    let details = {
      packageName: data.packageName,
      clientname: data.clientname,
      email: data.email,
      phone: data.phone,
      contactpreference: data.contactpreference,
      petname: data.petname,
      petage: data.petage,
      petsex: data.petsex,
      petbreed: data.petbreed,
      petcomment: data.petcomment,
    };

    let response = await fetch("http://localhost:5000", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus(submitted);
  };

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit}>
        <header className={classes.header}>
          <h3 className={classes.title}>Review and Submit</h3>
        </header>
        <div className={classes.container}>
          <h3>About You:</h3>
          <p className={classes.description}>
            <ul>
              <li>Name: {data.clientname}</li>
              <li>email: {data.email}</li>
              <li>phone: {data.phone}</li>
              <li>Your contact preference: {data.contactpreference}</li>
            </ul>
          </p>
        </div>

        <div className={classes.container}>
          <h3>About Your Pet:</h3>
          <p className={classes.description}>
            <ul>
              <li>Requested Training Level: {data.packageName}</li>
              <li>Pet's Name: {data.petname}</li>
              <li>Pet's Age: {data.petage}</li>
              <li>Pet's Sex: {data.petsex}</li>
              <li>Pet's Breed: {data.petbreed}</li>
              <li>Comments: {data.petcomment}</li>
            </ul>
          </p>
        </div>
        <div className={classes.buttonContainer}>
          {status === "unsent" && (
            <>
              <div>
                <button onClick={back}>Back</button>
                <button type="submit">Submit</button>
              </div>
            </>
          )}
          {status === sending && (
            <span className={classes.statusOK}>Sending... please wait...</span>
          )}
          {status === submitted && (
            <span className={classes.statusSUCCESS}>
              <br />
              Message sent! <br />
              Keep an eye on your email. We will be in touch! <br />
              <button onClick={props.onCancel}>CLOSE</button>
            </span>
          )}
        </div>
      </form>
    </div>
  );
};
export default Submit;
