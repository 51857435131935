import React from "react";
import classes from "./Form.module.css";
const PackageData = [
  {
    packageName: "The Lily Package",
    description: (
      <div className={classes.container}>
        Lily level is for dogs new to training or who have not worked with a
        trainer in awhile. This could be puppies or older dogs as well as owners
        who need some help learnming proper training techniques.
      </div>
    ),
    skills:
      "Focus/Look at me, Sit, Down, Stay/Wait, Leash manners, Bite inhibition, Potty, Leave it, Drop it, Come (short distances) Basic Socialization, Sound desensitizing",
    avgPrice: (
      <div className={classes.container}>
        The average cost of training at the Lily level is as follows. These
        prices are subject to negotiation during the free 30 minute
        consultation:
        <br />
        <br />
        <strong>1 lesson</strong> - $60
        <br />
        <strong>5 lessons</strong> - $270 ($54/lesson: 10% discount)
        <br />
        <strong>10 lessons</strong> - $480 ($48/lesson: 20% discount)
      </div>
    ),
  },
  {
    packageName: "The Lincoln Package",
    description: (
      <div className={classes.container}>
        Lincoln level is for dogs familiar with training and have strong
        foundational skills already. In this level, foundational skills (in the
        Lily) are honed while new advanced skills are also introduced.
      </div>
    ),
    skills:
      "Extended Look/Focus, Extended Sit, Extended Down, Extended Wait/Stay, Heel, Advanced Find, Advanced Fetch, Place/Bed, Off, Up, Greet Calmly, Come (distant), Whistle Training, Boundary Training, Escalators, Boots desensitizing, Teeth brushing, Nail clipping, Ear Desensitizing, ",
    avgPrice: (
      <div className={classes.container}>
        The average cost of training at the Lincoln level is as follows. These
        prices are subject to negotiation during the free 30 minute
        consultation:
        <br />
        <br />
        <strong>1 lesson</strong> - $75
        <br />
        <strong>5 lessons</strong> - $337.50 ($67.50/lesson: 10% discount)
        <br />
        <strong>10 lessons</strong> - $600 ($60/lesson: 20% discount)
      </div>
    ),
  },
  {
    packageName: "The Winston Package",
    description: (
      <div className={classes.container}>
        Winston level is for dogs that are having behavioral issues and need a
        professional too help modify the problem behavior. This can include any
        sign of fear or aggression that the dog may be exhibiting such as
        barking, cowering, growling, biting, etc.
      </div>
    ),
    skills:
      "Behavior modification focus; fear and aggression attenuation. Deals with barking/Aggression towards other dogs or people, resource guarding, running away/Fear, Excessive Mouthing/Biting",
    avgPrice: (
      <div className={classes.container}>
        The average cost of training at the Winston level is as follows. These
        prices are subject to negotiation during the free 30 minute
        consultation:
        <br />
        <br />
        <strong>1 lesson</strong> - $90
        <br />
        <strong>5 lessons</strong> - $405 ($81/lesson: 10% discount)
        <br />
        <strong>10 lessons</strong> - $720 ($72/lesson: 20% discount)
      </div>
    ),
  },
];
export default PackageData;
