import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import classes from "./Header.module.css";
import React, { useState } from "react";

const Header = () => {
  const [showSidebar, setShowSidebar] = useState("");

  const sidebarShow = () => {
    setShowSidebar(true);
  };

  const sidebarHide = () => {
    setShowSidebar(false);
  };

  return (
    <React.Fragment>
      {showSidebar && (
        <div className={classes.screen} onClick={sidebarHide}>
          <div className={classes.sidebar}>
            <FontAwesomeIcon
              icon={faBars}
              className={classes.icon}
              onClick={sidebarHide}
            />
            <ul>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about">About us</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#packages">Packages</a>
              </li>
              <li>
                <a href="mailto:pinpointcanine@gmail.com">Contact us!</a>
              </li>
            </ul>
          </div>
        </div>
      )}

      <div className={classes.header}>
        <div className={classes.logo}></div>
        <div className={classes.banner}>
          <span className={classes.color}>PINPOINT </span>
          <span className={classes.dog}>CANINE</span>
          <p className={classes.subtitle}>Las Vegas Based Dog Trainers</p>
        </div>
        <nav className={classes.nav}>
          <ul className={classes.navbarNav}>
            <li className={classes.navItem}>
              <a href="#home">HOME</a>
            </li>
            <li className={classes.navItem}>
              <a href="#about">ABOUT</a>
            </li>
            <li className={classes.navItem}>
              <a href="#services">SERVICES</a>
            </li>
            <li className={classes.navItem}>
              <a href="#packages">PACKAGES</a>
            </li>
            <li className={classes.navItem}>
              <a href="mailto:pinpointcanine@gmail.com">CONTACT</a>
            </li>
            <li className={classes.hamburger}>
              <FontAwesomeIcon
                icon={faBars}
                className={classes.icon}
                onClick={sidebarShow}
              />
            </li>
          </ul>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Header;
