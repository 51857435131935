import React from "react";
import Header from "./components/shared/Header";
import Home from "./components/body/Home";
import About from "./components/body/About";
import Packages from "./components/body/Packages";
import Services from "./components/body/Services";
import Footer from "./components/shared/Footer";
import layout from "./components/shared/layouts.module.css";

function App() {
  return (
    <React.Fragment>
      <div className={layout.grid}>
        <header>
          <Header></Header>
        </header>
        <main className={layout.main}>
          <Home />
          <About />
          <Services />
          <Packages />
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}

export default App;
