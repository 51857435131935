import classes from "./Card.module.css";
import "./CardImage.css";

const Card = (props) => {
  return (
    <div className={classes.card}>
      <div className={classes.cardtitle}>
        <h4>{props.title}</h4>
      </div>
      <div className={props.img}></div>
      <div className={classes.cardbody}>{props.children}</div>
      <div className={classes.cardfooter}>
        <button onClick={props.onClick}>More Details</button>
      </div>
    </div>
  );
};

export default Card;
