import React from "react";
import classes from "./Form.module.css";

const Step1 = (props) => {
  const { data, next } = props;
  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <h3 className={classes.title}>{data.packageName}</h3>
      </header>
      <h3>What is it?</h3>
      <div className={classes.description}>{data.description}</div>
      <div className={classes.container}>
        <h3>Some example skills taught in this level:</h3>
        <p className={classes.description}>{data.skills}</p>
      </div>
      <div className={classes.buttonContainer}>
        <strong>
          Schedule a<span className={classes.highlighted}>FREE</span>
          30 minute consultation and canine evaluation
        </strong>
        <br />
        <br />

        <button onClick={next}>Tell me more</button>
      </div>
    </div>
  );
};
export default Step1;
