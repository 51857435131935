// import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <>
      <div>
        <h3 class="footerhead">SITE MAP</h3>
        <ul>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about">About us</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#packages">Packages</a>
          </li>
          <li>
            <a href="mailto:pinpointcanine@gmail.com">Contact us!</a>
          </li>
        </ul>
      </div>
      {/* <div>
        <h3>SOCIALS</h3>
        <ul>
          <li>
            <a href="www.youtube.com" target="_blank">
              Youtube
            </a>
          </li>
          <li>
            <a href="www.instagram.com" target="_blank">
              Instagram
            </a>
          </li>
          <li>
            <a href="www.facebook.com" target="_blank">
              Facebook
            </a>
          </li>
        </ul>
      </div> */}
      <div>
        <h3 class="footerhead">CONTACT INFO</h3>
        <ul>
          <li>
            <a href="mailto:pinpointcanine@gmail.com">
              pinpointcanine@gmail.com
            </a>
          </li>
          <li>
            <a href="skype:live:.cid.a9ea3d6bbab4bbfc?call">(702) 833-9704</a>
          </li>
          <li>
            <a href="skype:live:.cid.a9ea3d6bbab4bbfc?chat">Chat with us!</a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Footer;
