import classes from "./About.module.css";

const About = () => {
  return (
    <>
      <div className={classes.about} id="about">
        <h2>About Us</h2>
        <p className={classes.text}>
          At Pinpoint, we believe in the
          <strong>
            {" "}
            least intrusive and minimally invasive training methods.{" "}
          </strong>
          This means that we do not make use of any aversive training
          techniques. Animals
          <strong>
            <em> do not </em>
            require prong collars, e-collars, or any type of training that is
            designed to punish,
          </strong>{" "}
          and we are happy to prove it! With our training, we promise to make
          both you and your pup happy.
        </p>
        <p className={classes.text}>
          We have trained extensively and built a strong, well-researched
          program so that they can bring the best and safest solutions for your
          training needs. Allow us to help your pup learn some important
          obedience, help with potty training, crate training, leash manners,
          healthy habits, and more. We handle any problematic behaviors that
          you've been struggling with. Contact us for help!
        </p>
      </div>

      <div className={classes.aboutimage}>
        <h3 className={classes.imagespan}>
          Need some peace for you and your pup?
          <br /> We can help.
        </h3>
      </div>
    </>
  );
};

export default About;
