import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Submit from "./FormWizardSubmit";

const FormWizard = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    packageName: props.packageName,
    description: props.description,
    skills: props.skills,
    avgPrice: props.avgPrice,
    petname: "",
    petage: "",
    petsex: "",
    petbreed: "",
    petcomment: "",
    clientname: "",
    email: "",
    phone: "",
    contactpreference: "",
  });
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const next = () => {
    setCurrentStep(currentStep + 1);
  };
  const back = () => {
    setCurrentStep(currentStep - 1);
  };
  switch (currentStep) {
    case 1:
      return (
        <Step1
          data={formData}
          handleChange={handleChange}
          next={next}
          onCancel={props.onCancel}
        />
      );
    case 2:
      return (
        <Step2
          data={formData}
          handleChange={handleChange}
          next={next}
          back={back}
          onCancel={props.onCancel}
        />
      );
    case 3:
      return (
        <Step3
          data={formData}
          handleChange={handleChange}
          next={next}
          back={back}
          onCancel={props.onCancel}
        />
      );
    default:
      return <Submit data={formData} back={back} onCancel={props.onCancel} />;
  }
};
export default FormWizard;
